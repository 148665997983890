<template>
  <div class="operator registration-page">
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="mr-3">{{ $t("imageListPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>
            <ImageUploader />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {

  name: "ImageList",

  created() {
   
  }

};
</script>
